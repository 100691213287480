<template>
  <section>
    <div
      id="addUserDrawer"
      class="offcanvas offcanvas-end"
      tabindex="-1"
      aria-labelledby="addUserDrawerLabel"
    >

      <b-card
        title="Add User"
        class="pb-auto"
      >
        <form
          ref="addUserForm"
          class="form-container"
          @submit.prevent="onSubmit"
        >
          <div class="mb-2 d-block">
            <div class="form-group">
              <label
                for="full_name"
                class="form-label mb-1"
              >User's Full Name</label>
              <input
                id="full_name"
                v-model="full_name"
                type="text"
                class="form-control"
                placeholder="Full Name"
              >
            </div>
            <div class="form-group">
              <label
                for="email"
                class="form-label mb-1"
              >User's Email</label>
              <input
                id="email"
                v-model="email"
                type="text"
                class="form-control"
                placeholder="Email Address"
              >
            </div>
          </div>
          <div
            class="d-block align-right"
          >
            <button
              type="reset"
              class="btn btn-outline-secondary d-inline"
              value="Reset"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-primary d-inline ml-1"
            >
              Submit
            </button>
          </div>
        </form>
      </b-card>
    </div>

  </section>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      full_name: '',
      email: '',
      message: '',
    }
  },
  methods: {
    onSubmit() {
      useJwt.addUser({
        email: this.email,
        full_name: this.full_name,
      })
        .then(console.log(`email: ${this.email}, full_name: ${this.full_name}`))
        .then(res => {
          const responseCode = res.status
          const responseText = res.statusText

          console.log('addUser() full response: ', res)
          console.log('Server reponse: ', responseCode, responseText)
          // this.message = `${responseCode} ${responseText}`
          this.message = `${responseText}`

          // Info notification on submit
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `User ${res.statusText.toLowerCase().trim()}`,
              text: `New user ${this.full_name} ${res.statusText.toLowerCase().trim()}.`,
              icon: 'UserPlusIcon',
              variant: 'info',
            },
          })
        })
        .catch(err => {
          // console.dir(err.response)
          console.error(`AddUser error: ${err.response.status} ${err.response.statusText}`)

          // Error notification on failure
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `User ${err.statusText}`,
              text: `Error: ${err.response.status} ${err.response.statusText}.`,
              icon: 'ExclamationTriangle',
              variant: 'error',
            },
          })
        })
    },
  },
}
</script>

<style>
  .align-right {
    text-align: right;
    }
</style>
